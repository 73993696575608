const data = [
    {
        id: "1",
        name: "NALA",
        raza: "BULLDOG FRANCES",
        gender: "HEMBRA",
        chip: "01239481293",
        photo: "01239481293.png",
        adi: null,
        obs: null,
    },
    {
        id: "2024-715-308",
        name: "KLOE ANTONIA",
        raza: "cocker Spaniel inglés",
        gender: "HEMBRA",
        chip: "N/A",
        photo: "09983993839.png",
        adi: "Detección olfativa",
        obs: "Perro de servicio alerta médica"
    },
    {
        id: "2024-715-309",
        name: "KIRA",
        raza: "Bulldog Ingles",
        gender: "HEMBRA",
        chip: "900215002960514",
        photo: "423345363.png",
        adi: "Detección olfativa",
        obs: "Perro de servicio alerta médica"
    },
    {
        id: "2024-715-311",
        name: "SASHI",
        raza: "POODLE FRENCH",
        gender: "HEMBRA",
        chip: "900115000707777",
        photo: "2094298492.png",
        adi: "Detección olfativa",
        obs: "Perro de servicio alerta médica"
    },
    {
        id: "2024-715-316",
        name: "HELA",
        raza: "GOLDEN RETREIVER",
        gender: "HEMBRA",
        chip: "953010003994035",
        photo: "09983993856.png",
        adi: "Detección olfativa",
        obs: "Perro de servicio alerta médica"
    },
]

const getInfoCertificate = (id, name) => {
    const dog = data.find(d => d.id === id && d.name.toLowerCase() === name.toLowerCase());
    return dog ? dog : false;
}

export {
    getInfoCertificate
}